define(['app', '$window'], (app, $window) => {
  const emailRegex = new RegExp('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)+$');

  const parseQuery = (url) => {
    if (!/\?/.test(url)) return {};
    const queryString = url.match(/\?(.*)/)[1];
    return queryString.split('&').reduce((acc, q) => {
      const [k, v] = q.split('=');
      return Object.assign({}, acc, {
        [k]: window.decodeURIComponent(v),
      });
    }, {});
  };

  const isValidEmail = (email) => emailRegex.test(email);

  const forgottenPasswordModal = () => {
    const comp = {};

    const _classNames = {
      formSection: 'forgottenPasswordModal_dialog_form',
      form: 'forgottenPasswordModal_dialog_form_form',
      returnToInput: 'forgottenPasswordModal_dialog_form_returnTo',
      input: 'forgottenPasswordModal_dialog_form_input',

      successSection: 'forgottenPasswordModal_dialog_success',
      successEmail: 'forgottenPasswordModal_dialog_success_email',

      invalidSection: 'forgottenPasswordModal_dialog_invalid',
      invalidTryAgainButton: 'forgottenPasswordModal_dialog_invalid_button',

      errorSection: 'forgottenPasswordModal_dialog_error',
      errorTryAgainButton: 'forgottenPasswordModal_dialog_error_button',
    };

    comp.init = (element) => {
      comp.element = element;

      comp.children = {};
      for (const [key, className] of Object.entries(_classNames)) {
        comp.children[key] = element.querySelector(`.${className}`);
      }

      comp.children.returnToInput.value = parseQuery($window.location.href)['returnTo'] || '';

      comp.attachListeners();

      return comp;
    };

    comp.attachListeners = () => {
      comp.children.form.addEventListener('submit', comp.onSubmit);
      comp.children.invalidTryAgainButton.addEventListener('click', () => comp.showSection('form'));
      comp.children.errorTryAgainButton.addEventListener('click', () => comp.showSection('form'));
    };

    comp.onSubmit = e => {
      e.preventDefault();
      const email = comp.children.input.value;
      if (!isValidEmail(email)) return comp.showSection('invalid');

      comp.postForm(comp.children.form.getAttribute('action'), comp._serializeForm())
        .then(function() {
          comp.children.successEmail.innerHTML = email;
          comp.showSection('success');
        })
        .catch(function() {
          comp.showSection('error');
        });
    };

    comp.postForm = (url, data) => new Promise((res, rej) => {
      app.ajax.post({
        url,
        send: data,
        requestHeader: {
          header: 'content-type',
          value: 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        success: res,
        error: rej,
      });
    });

    comp.showSection = (section) => {
      comp._hideAllSections();
      section = `${section}Section`;
      comp.children[section].classList.add(`${_classNames[section]}-show`);
      app.publish('modal/reloadAccessibleModalHelper', document.querySelector(`.forgottenPasswordModal_dialog_${section}_text`));
    };

    comp._serializeForm = () => {
      const encode = (s) => window.encodeURIComponent(s);
      const inputs = comp.children.form.querySelectorAll('input[name]');
      return Array.from(inputs).map(({ name, value }) => {
        return `${encode(name)}=${encode(value)}`;
      }).join('&');
    };

    comp._hideAllSections = () => {
      const sections = [
        'formSection',
        'successSection',
        'invalidSection',
        'errorSection'
      ];
      for (const section of sections) {
        comp.children[section].classList.remove(`${_classNames[section]}-show`);
      }
    };

    return comp;
  };

  return forgottenPasswordModal;
});
